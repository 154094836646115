<template>
  <ValidationObserver ref="observer"  v-slot="{ passes}">
    <mdb-container class="section-container-default mt-0">
      <LoginCard class="mt-5">


        <form  v-on:submit.prevent="onSubmitForm">
            <template v-if="!chooseLocation">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <h4 class="text-center">Sign In</h4>
                    <p class="w-100 mb-0 text-center">Enter Your Username/Email and Password to sign in</p>
                  </div>
                </div>
                <template v-if="errorMessage !== null">
                      <div class="alert alert-danger mt-2" role="alert">
                        {{errorMessage}}
                      </div>
                </template>

                <TextInput  v-model="loginForm.email" label="Email" class="mt-1 mb-1" />
                <TextInput type="password" v-model="loginForm.password" label="Password" class="mt-1 mb-1" />

                <template v-if="loginLoading">
                  <mdb-btn color="elegant" class="btn-block">
                    <span class="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  
                    <mdb-btn color="black" type="button" class="btn-block z-depth-0 def-btn-full-widt text-center" @click="passes(onSubmitForm)"><span class="btn-text">Sign In</span></mdb-btn>  
                </template>

                <mdb-btn color="black" type="button" class="btn-block z-depth-0 def-btn-full-widt text-center mt-1" @click="passes(onSubmitForm)"><span class="btn-text">Scan Qr</span></mdb-btn>  
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12 text-center">
                    <template v-if="user !== null">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                          <h4 class="text-center">Welcome</h4>
                                <p class="w-100 mb-0 text-center">Welcome {{user.user.name}}</p>
                      </div>
                    </div>

                      <validation-provider rules="required">
                        <label class="mb-0">Please select location</label>
                        <mdb-select v-model="filterShowrooms" @getValue="getSelectValue" placeholder="Select Location" :search="true" />

                        <template v-if="loginLoading">
                            <mdb-btn color="elegant" class="btn-block">
                              <span class="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                              Loading...
                            </mdb-btn>
                          </template>
                          <template v-else>
                            
                              <mdb-btn color="black" type="button" class="btn-block z-depth-0 def-btn-full-widt text-center" @click="passes(onSubmitForm)"><span class="btn-text">Select</span></mdb-btn>  
                          </template>

                      </validation-provider>
                    </template>
                </div>
              </div>
              </template>
                        
        </form>
       </LoginCard>
    </mdb-container>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import {TextInput} from '../components/common'
import { mapActions, mapGetters } from 'vuex'
import LoginCard from './auth/LoginCard';
import {mdbBtn, mdbContainer, mdbSelect} from 'mdbvue'
export default {
    components: {
      mdbSelect,
      mdbBtn,
      mdbContainer,
      LoginCard,
      ValidationObserver,
      ValidationProvider,
      TextInput
  },
    data : () => ({
        loginLoading : false,
        errorMessage : null,
        chooseLocation : null,
        user : null,
        showrooms : [],
        filterShowrooms : [],
        loginForm: {
            email: null,
            password: null,
            showroom : null,
            remember: false,
        },
    }),
    mounted(){
        const self = this;
        
        self.GetShowrooms().then((response) =>{
            if(response.length > 0){
              response.forEach((element,index) => {
                const d = {
                  text : element.label,
                  value : element.value,
                  selected : false,
                }

                if(index === 0){
                  d.selected = true
                }

                self.showrooms.push(d)

                
              })
            }
        })
    
    },
    methods : {
        ...mapActions('AuthModule',['login']),
        ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
        onSubmitForm() {
              var self =this
            this.validated = true
            this.$refs.observer.validate().then((isValid) => {
              console.log(isValid,"isValid")
              if (isValid) {
                if(self.loginLoading === false){
                  self.loginLoading = true
                  self.login(this.loginForm).then((response) => {
                    self.chooseLocation = true;
                      self.errorMessage = null
                      self.user = response
                      
                      if(response.location ===null && response.user.attr.location.length > 0){
                        const availableLocation = self.showrooms.filter((loc)=>{
                          return response.user.attr.location.includes(loc.value);
                          })

                          if(availableLocation.length > 0){
                            self.loginForm.showroom = response.user.attr.location[0]
                          }

                        self.filterShowrooms = availableLocation;
                        
                      }

                      if(response.location !== null){
                        // alert(0)
                        self.$router.go()
                      }
                            self.loginLoading = false

                  }).catch((error) => {
                    

                    console.log(error)
                    if(error.response.status === 401){
                        self.errorMessage = "Invalid Email or Password"
                    }
                    self.loginLoading = false
                    
                  })
                }
              }
            });
            /*  */
        },
        getSelectValue(v){
          this.loginForm.showroom = v
        }
    },
    /* computed: {
      ...mapGetters('AuthModule',
      [
        'Loading'
      ])
    }, */
}
</script>